<template>
  <div class="container mt-4">
    <!-- Filters Section -->
    <section
      v-if="$store.state.auth.ActiveUser.account_type === $AccountTypes.APPLICANT"
      class=""
    >
      <b-button
        variant="primary"
        class="w-25 mb-1"
        :disabled="freelance_loading"
        style="border-radius: 0;"
        @click="manageProfile"
      >
        <feather-icon
          icon="SettingsIcon"
          class="mr-50 "
        />
        <span>Manage your freelance profile</span>
      </b-button>
    </section>

    <b-overlay
      :show="is_loading"
      rounded="sm"
    >
      <b-card class="mb-4">
        <b-row>
          <b-col md="3">
            <p for="industry-filter">
              Filter by Industry
            </p>
            <v-select
              id="industry-filter"
              v-model="filters.industry"
              :options="industries"
              multiple
              placeholder="Select industries"
              label="value"
              :reduce="(value) => value.value"
            />
          </b-col>

          <b-col md="3">
            <p for="description-filter">
              Filter by Description
            </p>
            <b-form-input
              id="description-filter"
              v-model="filters.description"
              placeholder="Enter description"
            />
          </b-col>

          <b-col md="3">
            <p for="availability-filter">
              Filter by Availability
            </p>
            <v-select
              id="availability-filter"
              v-model="filters.availability"
              :options="availabilityOptions"
              placeholder="Select availability"
            />
          </b-col>

          <b-col md="3">
            <p for="sort-filter">
              Sort by
            </p>
            <v-select
              id="sort-filter"
              v-model="filters.sortBy"
              :options="sortOptions"
              label="name"
              placeholder="Sort by"
            />
          </b-col>
        </b-row>
      </b-card>

      <section
        v-if="records && records.length > 0"
        style="min-height: 60vh;"
      >
        <b-row>
          <b-col
            v-for="product in records"
            :key="product.fullname"
            cols="6"
            lg="4"
            class="mb-2"
          >
            <!-- v-if="product.stock > 0 || ($route.name === 'marketplace-manage-products')" -->
            <b-card
              :key="product.fullname"
              class="ecommerce-card"
              no-body
            >
              <b-card-body>

                <h3 class="item-name">
                  <b-link
                    class="text-body"
                  >
                    <b>{{ product.fullname }}</b>
                  </b-link>
                </h3>
                <b-card-text class="item-description">
                  {{ product.description }}
                </b-card-text>
                <hr>
              </b-card-body>

              <div
                class="item-options"
                style="margin-top: -20px;"
              >
                <div class="d-flex justify-content-between">
                  <div class="item-wrapper">
                    <div class="item-cost">
                      <h6
                        v-if="product.price_on_enquiry"
                        class="item-price ml-2"
                      >
                        Price On Enquiry
                      </h6>
                      <h6 class="item-price ml-2">
                        ${{ product.pricing }}
                      </h6>
                    </div>
                  </div>
                  <b-badge
                    class="float-right mr-2 mb-1"
                    :variant="product.availability.includes('Full') ? 'light-success': 'light-primary'"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ product.availability }}
                  </b-badge>
                </div>
              </div>

              <!-- Product Actions -->
              <div class="item-options text-center">

                <b-button
                  :variant="product.favourite ? 'outline-danger':'outline-dark'"
                  class="w-50"
                  style="border-radius: 0;"
                  @click="toggleFavourite(product.id)"
                >
                  <feather-icon
                    icon="HeartIcon"
                    class="mr-50 "
                  />
                  <span>{{ product.favourite ? 'Unfavorite' : 'Add to Favourites' }}</span>
                </b-button>
                <b-button
                  variant="primary"
                  tag="a"
                  class="w-50"
                  style="border-radius: 0;"
                  @click="$router.push({ name: 'freelancer-information', params: { freelancer_id: product.id } })"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>View More</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Pagination -->
        <section>
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.perPage"
                first-number
                align="center"
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </section>
      </section>
      <section v-else>

        <p class="text-center">
          No available records currently.
        </p>
      </section>
    </b-overlay>

    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { allIndustries } from '@/constants/datastore'
import { completionMixins } from '@/components/mixins/CompletionMixins'

export default {
  components: {
    vSelect,
  },
  mixins: [completionMixins],
  data() {
    return {
      freelance_loading: false,
      freelance_id: null,
      action: 'create',
      filters: {
        industry: [],
        description: '',
        availability: [],
        sortBy: null,
      },
      industries: allIndustries,
      availabilityOptions: ['Part-time (30hrs or less per week)', 'Full-time (40hrs or more per week)'],
      sortOptions: [
        { name: 'Relevancy' },
        { name: 'Industry' },
        { name: 'Date Posted' },
      ],
      all_availabilities: ['Part-time (30hrs or less per week)', 'Full-time (40hrs or more per week)'],

      records: [
        // {
        //   userName: 'Mark M',
        //   description: 'A highly experienced software developer providing freelance services in full-stack development. Specializes in JavaScript, Node.js, and cloud-based solutions. Known for delivering high-quality, scalable applications on time. Available for project-based or hourly work. Let’s build something innovative together!',
        //   availability: 'Part-time (30hrs or less per week)',
        //   price: 'Price On Enquiry',
        //   favourite: false,
        // },
        // {
        //   userName: 'Hannah Kylie',
        //   description: 'An expert in healthcare IT solutions with over 10 years of experience. Offering freelance services in EMR implementation, telemedicine platforms, and healthcare data security. Skilled at working with hospitals and clinics to improve patient outcomes through technology integration.',
        //   availability: 'Not Available',
        //   price: '$400/Week',
        //   favourite: true,
        // },
        // {
        //   userName: 'Joseph Mel',
        //   description: 'Financial consultant providing top-tier freelance services for wealth management, portfolio optimization, and financial risk assessments. With an extensive background in corporate finance and investment strategies, I offer tailored solutions to both individual clients and businesses.',
        //   availability: 'Full-time (40hrs or more per week)',
        //   price: '$1000/Month',
        //   favourite: false,
        // },
        // {
        //   userName: 'Gibson',
        //   description: 'E-learning specialist with a strong focus on digital curriculum design and educational technology. Offering freelance services to help schools, universities, and corporations develop interactive learning solutions, from LMS implementation to virtual classrooms.',
        //   availability: 'Part-time (30hrs or less per week)',
        //   price: '$10/Hour',
        //   favourite: false,
        // },
        // {
        //   userName: 'Sandra T',
        //   description: 'Professional graphic designer with a knack for creating visually stunning digital and print assets. Offering freelance design services, including branding, logo design, marketing materials, and web graphics. Passionate about crafting designs that leave a lasting impression.',
        //   availability: 'Full-time (40hrs or more per week)',
        //   price: '$2000/Month',
        //   favourite: false,
        // },
        // {
        //   userName: 'Alex P',
        //   description: 'Digital marketer offering freelance services in SEO, content marketing, and social media strategy. I help businesses improve their online presence and engagement through well-crafted campaigns and data-driven insights. Ready to help you grow your brand!',
        //   availability: 'Part-time (30hrs or less per week)',
        //   price: '$200/Week',
        //   favourite: false,
        // },
        // {
        //   userName: 'Leah W',
        //   description: 'Content writer and editor with over 8 years of experience. Offering freelance services for blog writing, technical writing, copywriting, and proofreading. Specializes in creating clear, compelling content tailored to client needs across various industries.',
        //   availability: 'Full-time (40hrs or more per week)',
        //   price: '$800/Week',
        //   favourite: false,
        // },
        // {
        //   userName: 'Chris D',
        //   description: 'Freelance web developer with a passion for creating user-friendly websites and web applications. Offering full-stack development services with expertise in React, Vue, and PHP. Dedicated to providing fast, secure, and mobile-optimized solutions for businesses and individuals.',
        //   availability: 'Not Available',
        //   price: '$3000/Week',
        //   favourite: false,
        // },
      ],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 12,
      },
      is_loading: false,
      favourites: [],
    }
  },
  computed: {
    paginatedCards() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.cards.slice(start, end)
    },
  },
  mounted() {
    const incompleteProfile = this.checkProfileCompletion()

    // this.$generatePresignedUrl('event-images/uuid-filename.jpg')
    //   .then(response => {
    // })

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }
    this.getFreelanceID()
    this.getAllPosts()
    // Load favourites from localStorage if they exist
    const storedFavourites = localStorage.getItem('favourites')
    if (storedFavourites) {
      this.favourites = JSON.parse(storedFavourites)
      // Update records to reflect favourite status from localStorage
      this.records.forEach(record => {
        record.favourite = this.favourites.includes(record.id)
      })
    }
  },
  created() {

  },
  methods: {
    manageProfile() {
      if (this.freelance_id) {
        const freelanceID = this.freelance_id
        this.$router.push({ name: 'freelancer-update', params: { freelancer_id: freelanceID } })
      } else {
        this.$router.push({ name: 'freelancer-create' })
      }
    },
    toggleFavourite(freelance_id) {
      // Check if the freelancer is already in favourites
      if (this.favourites.includes(freelance_id)) {
        // Remove from favourites
        this.favourites = this.favourites.filter(id => id !== freelance_id)
      } else {
        // Add to favourites
        this.favourites.push(freelance_id)
      }

      // Save the updated favourites list to localStorage
      localStorage.setItem('favourites', JSON.stringify(this.favourites))

      // Update the record's favourite state
      const record = this.records.find(item => item.id === freelance_id)
      if (record) {
        record.favourite = this.favourites.includes(freelance_id)
      }
    },
    truncateDescription(description) {
      if (description.length > 500) {
        return `${description.substring(0, 500)}...`
      }
      return description
    },
    getAllPosts() {
      this.is_loading = true
      this.$http.get('/api/freelancers').then(response => {
        const { success, posts, post_total } = response.data
        if (success) {
          this.records = posts

          this.records = posts.map(post => {
            // Check if the freelancer is already a favourite from localStorage
            post.favourite = this.favourites.includes(post.id)
            return post
          })
        }

        this.is_loading = false
      })
        .catch(error => {
          this.is_loading = false
        })
    },
    getFreelanceID() {
      this.freelance_loading = true
      this.$http.get('/api/freelancer-id').then(response => {
        const { success, freelance_id } = response.data
        if (success) {
          this.freelance_id = freelance_id
        }

        this.freelance_loading = false
      })
        .catch(error => {
          this.freelance_loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
